import { Button, SocialMediaIcon, SocialMediaIconProps } from '@kontentino/ui';
import React, { FC } from 'react';
import PageTypeUtils from 'app/utils/pageType';
import { useTranslation } from 'react-i18next';

type Props = {
  type: number;
  onClick: () => void;
  dataCy?: string;
  dataName?: string;
};

const SelectSocialTypeOption: FC<Props> = ({
  type,
  onClick,
  dataCy,
  dataName,
}) => {
  const { t } = useTranslation();

  return (
    <div
      tabIndex={0}
      className="tw-group tw-flex tw-h-[130px] tw-w-[120px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-between tw-rounded-md tw-border tw-border-grayscale-10 tw-bg-transparent tw-px-3 tw-pb-4 tw-pt-7 tw-text-center tw-capitalize tw-transition-all tw-duration-300 hover:tw-border-primary-100"
      onClick={onClick}
      data-cy={dataCy}
      data-name={dataName}
    >
      <div className="tw-flex tw-basis-[70%]">
        <SocialMediaIcon
          type={PageTypeUtils.getName(type) as SocialMediaIconProps['type']}
          variant="plain"
          size={40}
        />
      </div>
      <div className="tw-flex tw-min-h-[28px] tw-basis-[30%] tw-items-center tw-justify-center tw-text-md tw-font-medium tw-leading-4 tw-text-grayscale-180 group-hover:tw-hidden">
        {PageTypeUtils.getLabel(type)}
      </div>
      <Button
        className="tw-hidden tw-basis-[35%] group-hover:tw-block"
        variant="tertiary"
        size="small"
      >
        {t('connect')}
      </Button>
    </div>
  );
};

export default SelectSocialTypeOption;
