import { FC, useMemo } from 'react';
import { User } from 'types/User';
import { useLogoutUser } from 'modules/app/appSelector';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { Module } from 'config';
import Link from 'components/shared/Link';
import { Avatar, DropdownMenu, Icon } from '@kontentino/ui';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faGift } from '@fortawesome/pro-regular-svg-icons/faGift';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons/faGrid2';
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons/faPowerOff';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import VerticalNavigationDropdownOption from './UserActionDropdownOption';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { useTranslation } from 'react-i18next';
import { composeRoute, modalRoutes } from 'constants/routes';
import { useAccount } from 'modules/account/accountSelector';
import { faArrowRightToArc } from '@fortawesome/pro-regular-svg-icons/faArrowRightToArc';

type Props = {
  user: Pick<User, 'role' | 'avatar' | 'name'>;
};

const UserActions: FC<Props> = ({ user }) => {
  const logoutUser = useLogoutUser();
  const { t } = useTranslation();
  const { isSubscription } = useAccount();

  return useMemo(() => {
    return (
      <VerticalMenuItemWrapper className="tw-mb-4 tw-pl-4">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            className="hover:tw-text-primary-100"
            data-name="navbar_avatar"
          >
            <span className="tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-text-inherit tw-transition-all tw-duration-100 tw-ease-in-out">
              <Avatar
                src={user.avatar?.src}
                size={32}
                name={user.name}
                className="tw-border-grayscale-20 tw-transition-all tw-duration-100 tw-ease-in-out hover:tw-border-2 hover:tw-border-primary-20"
              />
            </span>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="tw-z-[99999] tw-w-[240px]"
              side="right"
              sideOffset={-10}
            >
              <UserPermissionGate scopes={UserPermission.ACCESS_SOCIAL_LOGIN}>
                <Link
                  to={composeRoute('settings.routes.me.routes.authorization')}
                  isRouterLink
                >
                  <DropdownMenu.Item data-name="navbar_avatar_social-login">
                    <VerticalNavigationDropdownOption
                      icon={
                        <Icon
                          icon={faArrowRightToArc}
                          className="tw-text-grayscale-100"
                        />
                      }
                      label={t('authorization')}
                    />
                  </DropdownMenu.Item>
                </Link>
              </UserPermissionGate>
              <DropdownMenu.Separator />
              <Link to={Module.OldApp.URL.MY_PROFILE}>
                <DropdownMenu.Item data-name="navbar_avatar_personal">
                  <VerticalNavigationDropdownOption
                    icon={<Icon icon={faUser} />}
                    label={t('navigation.profile.myAccount')}
                  />
                </DropdownMenu.Item>
              </Link>
              <DropdownMenu.Separator />
              <Link to={Module.OldApp.URL.MY_DEVICES}>
                <DropdownMenu.Item data-name="navbar_avatar_devices">
                  <VerticalNavigationDropdownOption
                    icon={<Icon icon={faMobile} />}
                    label={t('navigation.profile.myDevices')}
                  />
                </DropdownMenu.Item>
              </Link>
              <Link to={Module.OldApp.URL.QR_LOGIN}>
                <DropdownMenu.Item data-name="navbar_avatar_qr">
                  <VerticalNavigationDropdownOption
                    icon={<Icon icon={faGrid2} />}
                    label={t('navigation.profile.scanQRCode')}
                  />
                </DropdownMenu.Item>
              </Link>
              <DropdownMenu.Separator />
              <Link to={Module.OldApp.URL.NOTIFICATIONS_SETTINGS}>
                <DropdownMenu.Item data-name="navbar_avatar_notifications">
                  <VerticalNavigationDropdownOption
                    icon={<Icon icon={faBell} />}
                    label={t('navigation.profile.notificationSettings')}
                  />
                </DropdownMenu.Item>
              </Link>
              {isSubscription && (
                <Link to={{ hash: modalRoutes.REFERRAL_PROGRAM }}>
                  <DropdownMenu.Item data-name="navbar_avatar_referral-program">
                    <VerticalNavigationDropdownOption
                      icon={<Icon icon={faGift} />}
                      label={t('navigation.profile.referralProgram')}
                    />
                  </DropdownMenu.Item>
                </Link>
              )}
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                data-name="navbar_avatar_logout"
                onSelect={logoutUser}
              >
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faPowerOff} />}
                  label={t('navigation.profile.logout')}
                />
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </VerticalMenuItemWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscription, t, user.avatar?.src, user.name]);
};

export default UserActions;
