import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import env from 'config';
import {
  authJoiner,
  rejectExtender,
  requestLogger,
  responseChecker,
  responseLogger,
  timeoutExtender,
} from 'api/client/middleware';
import { isDevelopmentMode } from 'utils/helpers';
import { QueryClient } from 'react-query';
import { DEFAULT_HEADERS, DEFAULT_TIMEOUT } from 'constants/request';

const config: AxiosRequestConfig = {
  baseURL: env.AJAX_SERVICE_ENDPOINT,
  headers: DEFAULT_HEADERS,
  timeout: DEFAULT_TIMEOUT,
};

const client = axios.create(config);

if (isDevelopmentMode()) {
  client.interceptors.request.use(requestLogger);
  client.interceptors.response.use(responseLogger);
}

client.interceptors.request.use(authJoiner);
client.interceptors.request.use(timeoutExtender);
client.interceptors.response.use(responseChecker, rejectExtender);

type ApiClientRead = <T>(
  url: string,
  config?: AxiosRequestConfig,
) => Promise<T>;

type ApiClientWrite = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
) => Promise<T>;

export type ApiClientErrorParams<T = any> = {
  message?: string | undefined;
  name?: string | undefined;
  stack?: string | undefined;
  userMessage?: string | undefined;
  axiosError?: AxiosError<T>;
  response?: T;
};

export class ApiClientError<T = any> extends Error {
  userMessage: string | undefined;
  axiosError?: AxiosError<T>;
  response?: T | undefined;

  constructor(params?: ApiClientErrorParams<T>) {
    super();
    this.message = params?.message ?? '';
    this.name = params?.name ?? 'ApiClientError';
    this.stack = params?.stack;
    this.userMessage = params?.userMessage;
    this.axiosError = params?.axiosError;
    this.response = params?.response;
  }
}

export const get: ApiClientRead = client.get;

export const httpDelete: ApiClientRead = client.delete;

export const post: ApiClientWrite = client.post;

export const put: ApiClientWrite = client.put;

export const patch: ApiClientWrite = client.patch;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});
