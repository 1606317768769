import { TYPE } from '@kontentino/kontentino-constants/Pages';
import config from 'config';
import { FACEBOOK_SCOPES } from '@kontentino/kontentino-constants/FacebookScopes';
import { THREADS_SCOPES } from '@kontentino/kontentino-constants/ThreadsScopes';

export const SocialLoginError = {
  socialLoginClosed: 'Social login window closed before finishing.',
  pageTypeNotDefined: 'PageType must be defined before authorizing.',
  unableToAuthorize: 'Unable to authorize social profile',
};

const base = config.AJAX_SERVICE_ENDPOINT;

export const SocialLoginUrls = {
  [TYPE.TWITTER]: () => `${base}/pages/twitterLogin`,
  [TYPE.PINTEREST]: () => `${base}/pages/pinterestLogin`,
  [TYPE.LINKEDIN]: () => `${base}/pages/retrieveLinkedInAccessToken`,
  [TYPE.FACEBOOK]: (scopes: string) => `${base}/user/fbLogin?scopes=${scopes}`,
  [TYPE.INSTAGRAM]: (scopes: string) => `${base}/user/fbLogin?scopes=${scopes}`,
  [TYPE.GOOGLE_MY_BUSINESS]: () => `${base}/pages/googleMyBusinessLogin`,
  [TYPE.THREADS]: (scopes: string) =>
    `${base}/user/threadsLogin?scopes=${scopes}`,
} as Record<number, (s?: string) => string>;

export const SocialLoginMessageTypes = {
  FacebookAuthorized: 'facebook-social-login-done',
  FacebookError: 'facebook-social-login-error',
  TwitterAuthorized: 'twitter-social-login-done',
  TwitterError: 'twitter-social-login-error',
  LinkedinAuthorized: 'linkedin-social-login-done',
  LinkedinError: 'linkedin-social-login-error',
  PinterestAuthorized: 'pinterest-social-login-done',
  PinterestError: 'pinterest-social-login-error',
  GoogleMyBusinessAuthorized: 'google-my-business-social-login-done',
  GoogleMyBusinessError: 'google-my-business-social-login-error',
  ThreadsAuthorized: 'threads-social-login-done',
  ThreadsError: 'threads-social-login-error',
} as const;

export const FACEBOOK_NEW_PROFILE_SCOPES = [
  FACEBOOK_SCOPES.PUBLIC_PROFILE,
  FACEBOOK_SCOPES.PAGES_MANAGE_METADATA,
  FACEBOOK_SCOPES.PAGES_READ_USER_CONTENT,
  FACEBOOK_SCOPES.PAGES_SHOW_LIST,
  FACEBOOK_SCOPES.PAGES_MANAGE_POSTS,
  FACEBOOK_SCOPES.READ_ADS,
];

export const INSTAGRAM_NEW_PROFILE_SCOPES = [
  ...FACEBOOK_NEW_PROFILE_SCOPES,
  FACEBOOK_SCOPES.INSTAGRAM_BASIC,
  FACEBOOK_SCOPES.PAGES_READ_ENGAGEMENT,
  FACEBOOK_SCOPES.INSTAGRAM_CONTENT_PUBLISH,
];

export const THREADS_NEW_PROFILE_SCOPES = [
  THREADS_SCOPES.BASIC,
  THREADS_SCOPES.CONTENT_PUBLISH,
];
