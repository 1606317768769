import React, { Component, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ModalRouter } from 'context/ModalRouter';
import { Store } from 'redux';
import UserPermissionsProvider from 'components/user/permissions/UserPermissionsProvider';
import ReactQueryDevTools from 'components/shared/ReactQueryDevTools';
import store from 'store';
import { Dispatch } from 'types/Store';
import { AppThunks } from 'modules/app/appActions';
import { queryClient } from 'api/client';
import AccountOnboardingChecker from 'app/modules/onboarding/components/AccountOnboardingChecker';

// Workaround of code-splitting issue
import 'utils/hooks/useElementSize';
import 'utils/hooks/useWindowsSize';
import SocialLoginContextProvider from 'app/context/socialLogin';

type Props = {
  children: React.ReactNode;
  queryClient: QueryClient;
  store: Store;
};

class PrivateAppProviders extends Component<{ children: ReactNode }, Props> {
  constructor(props: Props) {
    super(props);
    store.dispatch<Dispatch>(AppThunks.initializeApp());
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevTools>
          <Provider store={store}>
            <UserPermissionsProvider>
              <AccountOnboardingChecker>
                <ModalRouter>
                  <SocialLoginContextProvider>
                    {this.props.children}
                  </SocialLoginContextProvider>
                </ModalRouter>
              </AccountOnboardingChecker>
            </UserPermissionsProvider>
          </Provider>
        </ReactQueryDevTools>
      </QueryClientProvider>
    );
  }
}

export default PrivateAppProviders;
