import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  FACEBOOK_NEW_PROFILE_SCOPES,
  INSTAGRAM_NEW_PROFILE_SCOPES,
  THREADS_NEW_PROFILE_SCOPES,
} from 'constants/socialLogin';

export const getDefaultScopesByPageType = (pageType: number) => {
  if (pageType === TYPE.FACEBOOK) {
    return FACEBOOK_NEW_PROFILE_SCOPES;
  }

  if (pageType === TYPE.INSTAGRAM) {
    return INSTAGRAM_NEW_PROFILE_SCOPES;
  }

  if (pageType === TYPE.THREADS) {
    return THREADS_NEW_PROFILE_SCOPES;
  }

  return [];
};
