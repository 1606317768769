import { useAccount } from 'modules/account/accountSelector';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const useSubscriptionInfo = () => {
  const {
    pageCount,
    isExpired,
    isTrial,
    lastTrialEndedAt,
    isSubscription,
    featuresPermissions,
    plan,
  } = useAccount();

  const trialDaysLeft = useMemo(() => {
    const today = dayjs();
    const trialEndDate = dayjs(lastTrialEndedAt);
    const difference = trialEndDate.diff(today, 'day');

    return isTrial && difference >= 0 ? difference + 1 : 0;
  }, [isTrial, lastTrialEndedAt]);

  const isTrialExpired = isExpired && isTrial;
  const isSubscriptionExpired = isExpired && isSubscription;
  const accountPlanType = plan.label.toLowerCase().replace(/\s/g, '_');
  const maximumPagesLimit = getMaxPagesToSelect();

  function getMaxPagesToSelect(): number | undefined {
    if (typeof plan.pageCount === 'number' && typeof pageCount === 'number') {
      return plan.pageCount - pageCount;
    }
  }

  function hasReachedProfilesLimit(profilesToAdd: number): boolean {
    return (
      typeof maximumPagesLimit === 'number' && profilesToAdd > maximumPagesLimit
    );
  }

  return {
    isTrial,
    trialDaysLeft,
    isTrialExpired,
    isSubscriptionExpired,
    featuresPermissions,
    accountPlanType,
    hasReachedProfilesLimit,
  };
};

export default useSubscriptionInfo;
