import { get, patch, post } from 'api/client';
import PageTypeUtils from 'app/utils/pageType';
import Config from 'config';
import store from 'store';
import { SocialLoginPage } from 'types/SocialLogin';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

async function getHeaders() {
  return { Authorization: `Bearer ${store.getState().app.authToken}` };
}

function getSocialNetworkForRequest(pageType: number) {
  let socialNetwork = PageTypeUtils.getName(pageType);

  if (socialNetwork === PageTypeUtils.name[TYPE.GOOGLE_MY_BUSINESS]) {
    socialNetwork = 'google';
  }

  return socialNetwork;
}

const SocialLoginApi = {
  requestLoginUrl: async (
    pageType: number,
    params: {
      redirectUrl?: string;
      scopes?: string[];
    },
  ): Promise<{
    loginUrl: string;
    redirectUrl: string | null;
    userKey?: string;
  }> => {
    const socialNetwork = getSocialNetworkForRequest(pageType);

    return get(`/api/${socialNetwork}/auth/redirect`, {
      baseURL: Config.SOCIAL_LOGIN_SERVICE_ENDPOINT,
      headers: await getHeaders(),
    });
  },
  addProfiles: async (
    pageType: number,
    data: {
      timezone: string;
      pages: SocialLoginPage[];
    },
  ): Promise<number[]> => {
    const socialNetwork = getSocialNetworkForRequest(pageType);

    const socialLoginResponse: SocialLoginPage[] = await post(
      `/api/${socialNetwork}/pages`,
      {
        pages: data.pages,
      },
      {
        baseURL: Config.SOCIAL_LOGIN_SERVICE_ENDPOINT,
        headers: await getHeaders(),
      },
    );

    return post(
      '/pages',
      {
        timezone: data.timezone,
        data: socialLoginResponse,
      },
      {
        baseURL: Config.API_SERVICE_ENDPOINT,
      },
    );
  },
  associateProfile: async (
    pageType: number,
    data: {
      pageId: number;
      externalPage: SocialLoginPage;
    },
  ): Promise<number[]> => {
    const socialNetwork = getSocialNetworkForRequest(pageType);

    const socialLoginResponse: SocialLoginPage[] = await post(
      `/api/${socialNetwork}/pages`,
      {
        pages: [data.externalPage],
      },
      {
        baseURL: Config.SOCIAL_LOGIN_SERVICE_ENDPOINT,
        headers: await getHeaders(),
      },
    );

    return patch(
      `/pages/${data.pageId}`,
      {
        externalPageId: socialLoginResponse[0].external_id,
      },
      {
        baseURL: Config.API_SERVICE_ENDPOINT,
      },
    );
  },
  fetchPages: async (
    userKey?: string,
  ): Promise<{
    status: 'waiting' | 'done';
    pages: SocialLoginPage[];
  }> => {
    if (!userKey) {
      throw new Error('User key is required');
    }

    return get('/api/fetch/pages', {
      params: { userKey },
      baseURL: Config.SOCIAL_LOGIN_SERVICE_ENDPOINT,
      headers: await getHeaders(),
    });
  },
};

export default SocialLoginApi;
