import React, { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import { Outlet } from 'react-router';
import Skeleton from 'react-loading-skeleton';

const AuthorizationRoute = lazy(
  () => import('app/modules/settings/modules/me/modules/authorization'),
);

export const settingsRouter: RouteObject = {
  path: routeConfig.settings.path,
  element: (
    <Suspense
      fallback={
        <div className="tw-h-full tw-p-4">
          <Skeleton width="100%" height="100%" />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Navigate
          to={routeConfig.settings.routes.me.routes.authorization.path}
        />
      ),
    },
    {
      path: routeConfig.settings.routes.me.path,
      element: <Outlet />,
      children: [
        {
          path: routeConfig.settings.routes.me.routes.authorization.path,
          element: <AuthorizationRoute />,
        },
      ],
    },
  ],
};
