import AppLayout from 'components/AppLayout';
import BackdropLoader from 'app/components/BackdropLoader';
import Initializing from 'components/Initializing';
import { useApp } from 'modules/app/appSelector';
import SubscriptionModals from 'app/components/SubscriptionModals';
import React, { FC, ReactNode, Suspense, useEffect } from 'react';
import { Navigate, Outlet, RouteObject, useLocation } from 'react-router-dom';
import useSubscriptionInfo from '../app/hooks/useSubscriptionInfo';
import { useCookies } from 'react-cookie';
import { USER_IS_TRIAL } from 'app/config/cookies';
import HotjarUtils from 'app/utils/hotjar';
import routes from 'constants/routes';
import { postsRouter } from 'app/modules/posts';
import { adsPlannerRouter } from 'app/modules/adsPlanner';
import { inAppNotificationsRouter } from 'app/modules/inAppNotifications';
import PrivateAppProviders from './components/PrivateAppProviders';
import { insightsRouter } from 'app/modules/insights';
import CommonModals from 'components/modals/CommonModals';
import DataLayer from 'app/utils/dataLayer';
import InAppNotificationsEventProvider from 'app/modules/inAppNotifications/InAppNotificationsEventProvider';
import { dashboardRouter } from 'app/modules/dashboard';
import { aiContentRouter } from 'app/modules/aiContent';
import { insightsLiteRouter } from 'app/modules/insightsLite';
import { brandHubRouter } from 'app/modules/brandHub';
import { onboardingRouter } from 'app/modules/onboarding';
import { settingsRouter } from 'app/modules/settings';

type Props = {
  children: ReactNode;
};

const PrivateApp: FC<Props> = ({ children }) => {
  const app = useApp();
  const location = useLocation();
  const { isTrial } = useSubscriptionInfo();
  const setCookie = useCookies([USER_IS_TRIAL])[1];

  useEffect(() => {
    if (app.isInitialized && !app.isUnauthorized) {
      DataLayer.push({
        location: {
          pathname: location.pathname,
          search: location.search,
        },
        event: DataLayer.events.APP_PAGE_VIEW,
      });
    }
  }, [
    app.isInitialized,
    app.isUnauthorized,
    location.pathname,
    location.search,
  ]);

  const isInitializing = !app.isInitialized && !app.isUnauthorized;
  const isUnauthorized = app.isUnauthorized;

  useEffect(() => {
    if (app.isInitialized && !app.isUnauthorized) {
      setCookie(USER_IS_TRIAL, isTrial);
      if (isTrial) {
        HotjarUtils.trackEvent(HotjarUtils.eventKey.trialLogin);
      }
    }
  }, [app.isInitialized, app.isUnauthorized, isTrial, setCookie]);

  if (isInitializing) {
    return <Initializing />;
  }

  if (isUnauthorized) {
    return <Navigate to={routes.LOGIN} />;
  }

  return (
    <AppLayout>
      <Suspense fallback={<BackdropLoader />}>
        {children}
        <CommonModals />
        <SubscriptionModals />
        <InAppNotificationsEventProvider />
      </Suspense>
    </AppLayout>
  );
};

export const privateRouter: RouteObject = {
  element: (
    <PrivateAppProviders>
      <PrivateApp>
        <Outlet />
      </PrivateApp>
    </PrivateAppProviders>
  ),
  children: [
    postsRouter,
    adsPlannerRouter,
    insightsRouter,
    inAppNotificationsRouter,
    dashboardRouter,
    aiContentRouter,
    brandHubRouter,
    insightsLiteRouter,
    adsPlannerRouter,
    onboardingRouter,
    settingsRouter,
  ],
};
